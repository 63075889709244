/* eslint-disable vue/return-in-computed-property */
<template>
    <div class="login-authenticate">
        <div class="tip-title">{{ loginTitle }}</div>
        <!-- determine what content should be presented according to the stage of the login (currently, only the signin with google is available) -->
        <div>
            <identity-provider-login :sso-login-url="ssoLoginUrl" />
            <div v-if="shouldShowUnexpectedError" class="login-authenticate__error">
                {{ unexpectedError }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import langConsts from '@/lang/en';
import loginConsts from '@/consts/loginConsts';
import router from '@/router';

export default {
    name: 'LoginAuthenticate',
    components: {
        IdentityProviderLogin: () => import('./LoginActions/IdentityProviderLogin.vue')
    },
    props: {
        didLoginAttemptFail: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('login', ['loginProcessInitialized', 'isAppLoginFlowSsoOnly', 'identityProviderType', 'ssoLoginUrl', 'userEmail']),

        loginTitle() {
            return langConsts.titleLogin;
        },
        unexpectedError() {
            return langConsts.unexpectedError;
        },
        shouldShowUnexpectedError() {
            return this.ssoLoginUrl === undefined || this.didLoginAttemptFail;
        }
    },
    async created() {
        if (this.loginProcessInitialized === false) {
            router.replace(`${loginConsts.urls.baseLoginAppUrl}${loginConsts.urls.urlPathFailedLoginAttempt}`);
            return;
        }
        if (this.isAppLoginFlowSsoOnly === false) {
            router.replace(`${loginConsts.urls.baseLoginAppUrl}${loginConsts.urls.urlPathFailedLoginAttempt}`);
        }
    }
};
</script>

<style lang="scss">
.login-authenticate {
    &__error {
        color: $outrageous-orange;
        margin-top: 1rem;
    }
}
</style>
